import React from 'react';

import i18next from "i18next";
import Helmet from 'react-helmet';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { useTranslation } from "react-i18next";

import './AboutUs.scss';

const AboutUsAngra = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Helmet>
                <html lang={i18next.language} />
                <title>{`${t('aboutus.title')} | ${process.env.REACT_APP_SERVER_NAME}`}</title>
                <meta name="description" content="Os melhores passeios do Brasil, Tours Guiados, Ingressos para atrações Turísticas, transfers para aeroporto e atividades no Brasil." />
                <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

                {/* Essential META Tags */}
                <meta property="og:title" content={`${t('aboutus.title')} | ${process.env.REACT_APP_SERVER_NAME}`} />
                <meta property="og:type" content="TravelAgency" />
                <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
                <meta property="og:url" content={process.env.REACT_APP_SERVER_URL + "sobre-nos"} />

                {/* Non-Essential, But Recommended */}
                <meta property="og:description" content="Os melhores passeios do Brasil, Tours Guiados, Ingressos para atrações Turísticas, transfers para aeroporto e atividades no Brasil." />
                <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                {/* Non-Essential, But Required for Analytics */}
                {/* <meta property="fb:app_id" content="your_app_id" /> */}

                <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
                <link rel="canonical" href={process.env.REACT_APP_SERVER_URL + "sobre-nos"} />
            </Helmet>

            <Header />
            <div className="container about-us">

                <div className="py-5 px-3 px-md-0">

                    <h1 className="text-center pb-4 display-4"> {t('aboutus.title')}</h1>

                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-6 pl-0 pr-0 pr-md-5">
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe width="100%" height="345" src="https://www.youtube.com/embed/7uGkd12Rtgo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 pt-4 pt-md-0">
                            <p>
                                {t("aboutus.angraDescription")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AboutUsAngra;