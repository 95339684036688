import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import './assets/sass/global.scss'

import ScrollToTop from "./infra/ScrollToTop/ScrollToTop";

import Routes from './routes';
import { Provider } from 'react-redux';
import store from './store/cart';

import { Helmet } from 'react-helmet';
import WhatsAppWidget from './components/WhatsAppWidget/WhatsAppWidget';

function App() {
  library.add(fas, fal, fad, fab);

  return (
    <div className="App">
      <Provider store={store} >
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <Helmet>
              <link rel='icon' href={`${process.env.REACT_APP_SERVER_FAVICON}` || `/c2rio-favicon.png`} />
              <meta name="facebook-domain-verification" content={`${process.env.REACT_APP_FACEBOOK_DOMAIN_VERIFICATION}`} />                 
            </Helmet>
            <ScrollToTop />
            <Routes />
            <WhatsAppWidget />
          </BrowserRouter>
        </I18nextProvider>
      </Provider>


    </div>
  );
}

export default App;